<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--      User e-mail configuration      -->
    <p class="h4 mt-50">
      {{ $t('gEmail.settings.UserEmailConfiguration') }}
    </p>

    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="MailIcon"
        />

        <span class="ml-25">
          {{ $t('gEmail.settings.hereYouWillDefineYourCompany') }}
        </span>
      </div>
    </b-alert>

    <b-row>
      <b-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-group :label="$t('Name')">
            <b-form-input
              v-model="email.name"
              :placeholder="$t('Name')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-group :label="$t('gEmail.settings.EnterYourEmailAddress')">
            <b-form-input
              v-model="email.senderEmail"
              :placeholder="$t('gEmail.settings.EnterYourEmailAddress')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('gEmail.settings.YourNameOfSender')"
        >
          <!--          rules="required"-->
          <b-form-group :label="$t('gEmail.settings.YourNameOfSender')">
            <template #label>
              <div
                class="d-flex align-items-center mb-n25"
                style="column-gap: .1rem"
              >
                {{ $t('gEmail.settings.YourNameOfSender') }}

                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="flat-primary"
                  class="btn-icon p-25"
                  size="sm"
                  :title="$t('gEmail.settings.TipYourNameOfSender')"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </div>
            </template>

            <b-form-input
              v-model="email.senderName"
              :placeholder="$t('gEmail.settings.YourNameOfSender')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col sm="12">
        <sw-select :name="$t('ShareTo')">
          <v-select
            v-model="email.sharedUsers"
            :options="userList"
            label="firstName"
            :filterable="false"
            multiple
            :placeholder="$t('ShareTo')"
            @search="searchUsers"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>

            <template #option="{ firstName, lastName, avatar }">
              <div class="d-flex align-items-center">
                <avatar
                  :user="{ firstName, lastName, avatar }"
                  class="mr-25"
                />
              </div>
            </template>

            <template #selected-option="{ firstName, lastName, avatar }">
              <div class="d-flex">
                <avatar
                  :user="{ firstName, lastName, avatar }"
                  class="mr-25"
                  text-variant="text-white"
                />
              </div>
            </template>
          </v-select>
        </sw-select>
      </b-col>
    </b-row>

    <!--      Post server settings      -->
    <p class="h4 mt-50">
      {{ $t('gEmail.settings.PostServerSettings') }}
    </p>

    <!--    <b-alert-->
    <!--      show-->
    <!--      variant="primary"-->
    <!--      class="d-flex flex-column flex-md-row"-->
    <!--    >-->
    <!--      <div class="alert-body">-->
    <!--        <feather-icon-->
    <!--          class="mr-25"-->
    <!--          icon="MailIcon"-->
    <!--        />-->

    <!--        <span class="ml-25">-->
    <!--          {{ $t('gEmail.settings.SaleswizardConfiguratesPostOffice') }}-->
    <!--        </span>-->
    <!--      </div>-->

    <!--      <div class="alert-body">-->
    <!--        <b-button-->
    <!--          variant="outline-primary"-->
    <!--          class="mt-n50 mt-md-0"-->
    <!--        >-->
    <!--          {{ $t('Look') }}-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </b-alert>-->

    <b-row>
      <b-col sm="12"
             md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('gEmail.settings.EmailPassword')"
          rules="required"
        >
          <b-form-group :label="$t('gEmail.settings.EmailPassword')">
            <b-form-input
              v-model="email.imapPass"
              :placeholder="$t('gEmail.settings.EmailPassword')"
              :state="errors.length > 0 ? false:null"
              type="password"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!--      <b-col sm="12"-->
      <!--             md="6"-->
      <!--      >-->
      <!--        <validation-provider-->
      <!--          v-slot="{ errors }"-->
      <!--          :name="$t('SendFolder')"-->
      <!--        >-->
      <!--          <b-form-group>-->
      <!--            <template #label>-->
      <!--              <div-->
      <!--                class="d-flex align-items-center mb-n25"-->
      <!--                style="column-gap: .1rem"-->
      <!--              >-->
      <!--                {{ $t('SendFolder') }}-->

      <!--                <b-button-->
      <!--                  v-b-tooltip.hover.v-primary-->
      <!--                  variant="flat-primary"-->
      <!--                  class="btn-icon p-25"-->
      <!--                  size="sm"-->
      <!--                  :title="`${$t('DefaultValue')}: INBOX.Sent`"-->
      <!--                >-->
      <!--                  <feather-icon icon="InfoIcon" />-->
      <!--                </b-button>-->
      <!--              </div>-->
      <!--            </template>-->
      <!--            <b-form-input-->
      <!--              v-model="email.sendFolder"-->
      <!--              :placeholder="$t('SendFolder')"-->
      <!--              :state="errors.length > 0 ? false:null"-->
      <!--              type="text"-->
      <!--            />-->

      <!--            <small class="text-danger">{{ errors[0] }}</small>-->
      <!--          </b-form-group>-->
      <!--        </validation-provider>-->
      <!--      </b-col>-->
    </b-row>

    <div class="divider text-primary divider-left">
      <div class="divider-text font-medium-2">
        IMAP
      </div>
    </div>
    <b-row class="mb-25">
      <b-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('User')"
          rules="required"
        >
          <b-form-group :label="$t('User')">
            <b-form-input
              v-model="email.imapUser"
              :placeholder="$t('User')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="8"
        lg="9"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('gEmail.settings.IncomingMailServer')"
          rules="required"
        >
          <b-form-group :label="$t('gEmail.settings.IncomingMailServer')">
            <b-form-input
              v-model="email.imapHost"
              :placeholder="$t('gEmail.settings.IncomingMailServer')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="4"
        lg="3"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Port')"
        >
          <!--          rules="required|numeric"-->
          <b-form-group>
            <template #label>
              <div
                class="d-flex align-items-center mb-n25"
                style="column-gap: .1rem"
              >
                {{ $t('Port') }}

                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="flat-primary"
                  class="btn-icon p-25"
                  size="sm"
                  :title="`${$t('DefaultValue')}: 993`"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </div>
            </template>
            <b-form-input
              v-model="email.imapPort"
              :placeholder="$t('Port')"
              :state="errors.length > 0 ? false:null"
              type="number"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col sm="12">
        <b-button v-if="email.imapHost && email.imapPort && email.imapUser && email.imapPass && !imapTestConnected"
                  variant="primary"
                  @click="testImapConnection"
        >
          {{ $t('CheckConnection') }}
        </b-button>
        <div v-if="imapTestConnected">
          <b-alert show>
            <div class="alert-body">
              {{ $t('ConnectedSuccessfully') }}
            </div>
          </b-alert>

          <validation-provider
            v-slot="{ errors }"
            :name="$t('SendFolder')"
          >
            <sw-select :name="$t('SendFolder')"
                       :tip="`${$t('DefaultValue')}: INBOX.Sent`"
            >
              <v-select
                v-model="email.sendFolder"
                :options="imapFolders"
                :filterable="false"
                :placeholder="$t('SendFolder')"
                :clearable="false"
              >
                <template #no-options>
                  {{ $t('NoData') }}
                </template>
              </v-select>
            </sw-select>
            <!--              <b-form-input-->
            <!--                v-model="email.sendFolder"-->
            <!--                :placeholder="$t('SendFolder')"-->
            <!--                :state="errors.length > 0 ? false:null"-->
            <!--                type="text"-->
            <!--              />-->

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :name="$t('DraftFolder')"
          >
            <sw-select :name="$t('DraftFolder')"
                       :tip="`${$t('DefaultValue')}: INBOX.Drafts`"
            >
              <v-select
                v-model="email.draftFolder"
                :options="imapFolders"
                :filterable="false"
                :placeholder="$t('DraftFolder')"
                :clearable="false"
              >
                <template #no-options>
                  {{ $t('NoData') }}
                </template>
              </v-select>
            </sw-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :name="$t('HideFolders')"
          >
            <sw-select :name="$t('HideFolders')"
                       :tip="`${$t('SelectFoldersToHide')}`"
            >
              <v-select
                v-model="email.hideFolders"
                :options="imapFolders"
                :filterable="false"
                multiple
                :placeholder="$t('HideFolders')"
                :close-on-select="false"
                :selectable="folder => folder !== 'INBOX'"
              >
                <template #no-options>
                  {{ $t('NoData') }}
                </template>
              </v-select>
            </sw-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </div>

      </b-col>
    </b-row>

    <div class="divider text-primary divider-left">
      <div class="divider-text font-medium-2">
        SMTP
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('User')"
          rules="required"
        >
          <b-form-group :label="$t('User')">
            <b-form-input
              v-model="email.smtpUser"
              :placeholder="$t('User')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="8"
        lg="9"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('gEmail.settings.EmergingMailServer')"
          rules="required"
        >
          <b-form-group :label="$t('gEmail.settings.EmergingMailServer')">
            <b-form-input
              v-model="email.smtpHost"
              :placeholder="$t('gEmail.settings.EmergingMailServer')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="4"
        lg="3"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Port')"
        >
          <!--          rules="required|numeric"-->
          <b-form-group>
            <template #label>
              <div
                class="d-flex align-items-center mb-n25"
                style="column-gap: .1rem"
              >
                {{ $t('Port') }}

                <b-button
                  v-b-tooltip.hover.v-primary
                  variant="flat-primary"
                  class="btn-icon p-25"
                  size="sm"
                  :title="`${$t('DefaultValue')}: 465`"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </div>
            </template>
            <b-form-input
              v-model="email.smtpPort"
              :placeholder="$t('Port')"
              :state="errors.length > 0 ? false:null"
              type="number"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col sm="12">
        <b-button v-if="email.smtpHost && email.smtpPort && email.smtpUser && email.imapPass && !smtpTestConnected"
                  variant="primary"
                  @click="testSmtpConnection"
        >
          {{ $t('CheckConnection') }}
        </b-button>
        <div v-if="smtpTestConnected">
          <b-alert show>
            <div class="alert-body">
              {{ $t('ConnectedSuccessfully') }}
            </div>
          </b-alert>
        </div>
      </b-col>
    </b-row>

    <!--      Privacy Settings      -->
    <!--    <p class="h4 mt-50">-->
    <!--      {{ $t('gEmail.settings.PrivacySettings') }}-->
    <!--    </p>-->

    <!--    <b-form-checkbox class="custom-control-primary">-->
    <!--      {{ $t('gEmail.settings.AllIncomingAndOutgoingMessages') }}-->
    <!--    </b-form-checkbox>-->

    <!--      Message synchronization settings      -->
    <!--    <p class="h4 mt-2">-->
    <!--      {{ $t('gEmail.settings.MessageSynchronizationSettings') }}-->
    <!--    </p>-->

    <!--    <validation-provider-->
    <!--      v-slot="{ errors }"-->
    <!--      :name="$t('gEmail.settings.SynchronizeMessagesFromDate')"-->
    <!--    >-->
    <!--      &lt;!&ndash;      rules="required"&ndash;&gt;-->
    <!--      <sw-select :name="$t('gEmail.settings.SynchronizeMessagesFromDate')">-->
    <!--        <sw-select-fpr>-->
    <!--          <flat-pickr-->
    <!--            class="form-control"-->
    <!--            :config="{ locale: locale, ...flatPickerConfig }"-->
    <!--            :state="errors.length > 0 ? false:null"-->
    <!--            value=""-->
    <!--          />-->
    <!--        </sw-select-fpr>-->

    <!--        <small class="text-danger">{{ errors[0] }}</small>-->
    <!--      </sw-select>-->
    <!--    </validation-provider>-->
    <!--    <b-form-checkbox checked="true"-->
    <!--                     disabled-->
    <!--                     class="custom-control-primary mt-75"-->
    <!--    >-->
    <!--      {{ $t('gEmail.settings.MessagesDeletedFromSaleswizardCRM') }}-->
    <!--    </b-form-checkbox>-->

    <!--      Message synchronization settings      -->
    <!--    <p class="h4 mt-2">-->
    <!--      {{ $t('gEmail.settings.AutomaticAssignmentOfMessages') }}-->

    <!--      <b-button-->
    <!--        v-b-tooltip.hover.v-primary-->
    <!--        variant="flat-primary"-->
    <!--        class="btn-icon p-25"-->
    <!--        size="sm"-->
    <!--        :title="$t('InPreparation')"-->
    <!--      >-->
    <!--        <feather-icon icon="InfoIcon" />-->
    <!--      </b-button>-->
    <!--    </p>-->
    <!--    <b-form-checkbox-->
    <!--      class="custom-control-primary"-->
    <!--      disabled-->
    <!--    >-->
    <!--      {{ $t('gEmail.settings.IndicateSuggestedContactsToLink') }}-->
    <!--    </b-form-checkbox>-->
    <!--    <b-form-checkbox-->
    <!--      class="custom-control-primary mt-75"-->
    <!--      disabled-->
    <!--    >-->
    <!--      {{ $t('gEmail.settings.AutomaticallyGiveEmailsWithContactsAboutOneThread') }}-->
    <!--    </b-form-checkbox>-->

    <b-form-group :label="$t('Footer')">
      <div class="message-editor pb-3">
        <quill-editor
          id="quil-content"
          ref="footer_editor"
          v-model="email.footer"
          style="height: 250px"
          :options="editorOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <!-- Add a bold button -->
            <span class="ql-formats">
              <button class="ql-clean" />
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-link" />
            </span>
            <span class="ql-formats">
              <button
                class="ql-script"
                value="sub"
              />
              <button
                class="ql-script"
                value="super"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              />
              <button
                class="ql-list"
                value="bullet"
              />
            </span>
            <span class="ql-formats">
              <button
                class="ql-indent"
                value="-1"
              />
              <button
                class="ql-indent"
                value="+1"
              />
            </span>

            <button class="ql-image" />

            <!-- You can also add your own -->
            <span class="ql-formats">
<!--              <b-button-->
<!--                id="custom-button"-->
<!--                variant="primary"-->
<!--                class="mr-25"-->
<!--                :disabled="fileLoading"-->
<!--                @click="$refs.image_input.click()"-->
<!--              >-->
<!--                <span class="text-white">-->
<!--                  <feather-icon icon="ImageIcon" />-->
<!--                </span>-->
<!--                <input-->
<!--                  id="image_input"-->
<!--                  ref="image_input"-->
<!--                  style="display: none"-->
<!--                  type="file"-->
<!--                  accept="image/*"-->
<!--                  @change="uploadFile"-->
<!--                >-->
<!--              </b-button>-->

              <b-button
                id="custom-button"
                variant="success"
                class="w-auto"
                @click="isModalOpen = true"
              >
                <span class="text-white">
                  <feather-icon
                    icon="CodeIcon"
                    class="mr-25"
                  />
                </span>

                <span class="text-white">
                  {{ $t('ShortCodes') }}
                </span>
              </b-button>
            </span>
          </div>
        </quill-editor>
      </div>
      <!--      <b-form-textarea v-model="email.footer"-->
      <!--                       rows="4"-->
      <!--      />-->
    </b-form-group>

    <b-modal
      :visible="isModalOpen"
      :title="$t('Codes')"

      size="lg"
      hide-footer
      no-close-on-backdrop
      @hide="isModalOpen = false"
    >
      <shortcodes-modal :show-sections="['General']"
                        @close-modal="isModalOpen = false"
      />
    </b-modal>

    <div class="mt-1 d-flex justify-content-center">
      <b-button
        variant="success"
        :disabled="invalid || !imapTestConnected || !smtpTestConnected"
        @click="save"
      >
        {{ $t('gEmail.settings.SynchronizeYourEmailAccount') }}
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VBTooltip } from 'bootstrap-vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import { ADD_USER_MAILBOX, GET_USER_MAILBOX, UPDATE_USER_MAILBOX } from '@/@constants/mutations'
import { quillEditor } from 'vue-quill-editor'
import { USERS_SEARCH } from '@/@constants/fields'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  components: {
    ShortcodesModal,
    vSelect,
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
  },

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },

  emits: ['update'],

  data: () => ({
    isModalOpen: false,
    isLoading: false,
    fileLoading: false,
    userList: [],
    imapTestConnected: false,
    imapFolders: [],
    smtpTestConnected: false,
    email: {
      name: '',
      senderEmail: '',
      senderName: '',
      sendFolder: 'INBOX.Sent',
      draftFolder: 'INBOX.Drafts',
      hideFolders: [],
      imapUser: '',
      imapPass: '',
      imapHost: '',
      smtpUser: '',
      smtpPass: '',
      smtpHost: '',
      footer: '',
      sharedUsers: [],

      smtpPort: 465,
      imapPort: 993,
    },

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },

    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
      placeholder: '',
    },

    footerImages: [],
  }),

  computed: {
    locale() {
      return this.$i18n.locale
    },
  },

  mounted() { this.ini() },

  methods: {
    async uploadFile(inputResponse) {
      this.fileLoading = true
      const { files } = inputResponse.target

      const imageData = {
        token: null,
        base64: '',
        cid: '',
        filename: '',
      }

      const formData = new FormData()
      files.forEach(file => {
        const extension = file.name.split('.').at(-1)
        const uniqueId = `img${Math.random().toString(36).substring(2, 15)}`
        // const name = file.name.replace(`.${extension}`, '').replace(`${extension}`, '')
        formData.append('files[]', file, `${uniqueId}.${extension}`)
        imageData.cid = uniqueId
        imageData.filename = uniqueId
      })
      const response = await axiosIns.post('storage/1/upload', formData, { noApi: true })

      // eslint-disable-next-line prefer-destructuring
      imageData.token = response.data.data.files[0]

      const fr = new FileReader()

      fr.onload = () => {
        imageData.base64 = fr.result
        const range = this.$refs.footer_editor.quill.getSelection()
        if (range) {
          this.$refs.footer_editor.quill.insertEmbed(range.index, 'image', imageData.base64)
          this.footerImages.push(imageData)
          this.$refs.image_input.value = null
          this.fileLoading = false
        }
      }

      fr.readAsDataURL(files[0])

      /*
        {
          token,
          base64,
          cid,
          filename
        }
       */
    },
    async searchUsers(search, isLoading) {
      this.userList = await this.selectSearch(search, isLoading, USERS_SEARCH, 'firstName,lastName', '1/users', 25)
    },
    async ini() {
      const { id } = this

      if (id) await this.load(id)
    },

    async load(id) {
      if (id) {
        this.isLoading = true

        const { item } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOX}`, id)

        if (item.footerImageFiles.length) {
          const responses = await Promise.all(item.footerImageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
          this.footerImages = responses.map((e, i) => {
            const filename = `${e.data.data.name}`
            const base64 = `data:image/png;base64,${e.data.data.file}`
            return {
              token: item.footerImageFiles[i]?.token,
              base64,
              cid: filename,
              filename,
            }
          })

          this.footerImages.forEach(image => {
            // cid, filename, token, base64
            if (item.footer.includes(image.cid)) {
              item.footer = item.footer.replaceAll(`cid:${image.filename}`, image.base64)
            }
          })
        }

        try {
          const resp = await axiosIns.get(`1/users/me/mailboxes/${id}/getFolders`)
          if (resp.data.data) {
            // this.imapTestConnected = true
            this.imapFolders = resp.data.data
          }
        } catch (err) {
          console.error(err)
        } finally {
          item.imapPass = ''
          item.smtpPass = ''

          item.smtpPort = item.smtpPort || 465
          item.imapPort = item.imapPort || 993

          this.email = item

          this.isLoading = false
        }
      }
    },

    async testImapConnection() {
      try {
        const resp = await axiosIns.post('1/users/me/mailboxes/validateImapAndGetFolders', {
          imapUser: this.email.imapUser,
          imapPass: this.email.imapPass,
          imapHost: this.email.imapHost,
          imapPort: this.email.imapPort,
        })

        if (resp.data.data) {
          this.imapTestConnected = true
          this.imapFolders = resp.data.data

          const findDraft = this.imapFolders.find(e => e.toLowerCase().includes('draft'))
          this.email.draftFolder = findDraft || ''
          const findSent = this.imapFolders.find(e => e.toLowerCase().includes('sent'))
          this.email.sendFolder = findSent || ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    async testSmtpConnection() {
      try {
        await axiosIns.post('1/users/me/mailboxes/validateSmtp', {
          smtpUser: this.email.smtpUser,
          smtpPass: this.email.imapPass,
          smtpHost: this.email.smtpHost,
          smtpPort: this.email.smtpPort,
        })

        this.smtpTestConnected = true
      } catch (err) {
        console.log(err)
      }
    },

    getPayload() {
      const payload = this.email

      payload.smtpPass = payload.imapPass

      payload.sharedUsers = payload.sharedUsers.mapKeys('id')

      delete payload.user
      delete payload.createdAt

      return payload
    },

    async save() {
      this.loading = true

      const payload = this.getPayload()

      if (payload) await this.send(payload)

      this.loading = false
    },

    uploadImages(e) {
      console.log(e)
    },

    async send(payloadData) {
      this.loading = true

      const action = payloadData.id ? UPDATE_USER_MAILBOX : ADD_USER_MAILBOX

      const payload = {
        ...payloadData,
        smtpPort: Number(payloadData.smtpPort || 465),
        imapPort: Number(payloadData.imapPort || 993),
        footer: payloadData.footer?.replaceAll('<p>', '<div>').replaceAll('</p>', '</div>'),
      }

      const imagesCopy = this.footerImages.map(e => ({ ...e, finded: false }))
      imagesCopy.forEach((image, index) => {
        // cid, filename, token, base64
        if (payload.footer.includes(image.base64)) {
          payload.footer = payload.footer.replaceAll(image.base64, `cid:${image.filename.replaceAll('.png', '').replaceAll('.jpg', '').replaceAll('.jpeg', '').replaceAll('.webp', '')
            .replace(/[^a-z0-9]/gi, '')}`)
          imagesCopy[index].finded = true
        }
      })
      payload.footerImageFiles = imagesCopy.filter(e => e.finded).map(e => e.token)

      const [content, tokens] = await this.scanContentAndReplaceBaseWithCid(payload.footer)

      if (tokens.length) {
        payload.footerImageFiles.push(...tokens)
        payload.footer = content
      }

      // eslint-disable-next-line no-unreachable
      const item = await this.$store.dispatch(`mailbox/${action}`, payload)

      this.$emit('update', payload.id ? payload : item)

      const update = this.email
      update.name = ''
      update.senderEmail = ''
      update.senderName = ''
      update.sendFolder = ''
      update.draftFolder = ''
      update.hideFolders = []
      update.imapUser = ''
      update.imapPass = ''
      update.imapHost = ''
      update.smtpUser = ''
      update.smtpPass = ''
      update.smtpPort = 465
      update.imapPort = 993
      update.smtpHost = ''
      update.footer = ''

      this.footerImages = []

      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.quil-content-small .ql-editor {
  min-height: 15rem !important;
}
</style>
